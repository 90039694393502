.container-date-range {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.3px solid #437097;
    border-radius: 18px;
    opacity: 1;
    display: flex;
    align-items: center;
    padding: 6px 0 6px 12px;
    width: 100%;
}

.date-range-label {
    font: normal normal normal 12px/16px Instrument Sans;
    letter-spacing: 0px;
    color: #3a3a3a99;
    opacity: 1;
    margin-right: 4px;
}

.date-range-separator {
    font: normal normal normal 12px/16px Instrument Sans;
    letter-spacing: 0px;
    color: #3a3a3a99;
    opacity: 1;
    border: 0;
    margin-left: 12px;
    margin-right: 12px;
}

.date-range-input {
    font: normal normal normal 12px/16px Instrument Sans;
    letter-spacing: 0px;
    color: #3a3a3a99;
    opacity: 1;
    border: 0;
    outline: none;
    background-color: transparent;
    padding: 0;
    /* Elimina el padding por defecto */
    margin: 0;
    /* Elimina el margen por defecto */
    min-width: 0;
    /* Asegúrate de que no haya un ancho mínimo que cause separación */
}