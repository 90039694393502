.itp-menu-right-container.open {
  background: #f6f6f9 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 0.72;
  position: fixed;
  width: 100vw;
  height: 100%;
  z-index: 1000;
}

.itp-menu-right-content.open.w50 {
  width: 50%;
  transition: width 0.2s;
}

.itp-menu-right-content.open {
  top: 7rem;
  position: fixed;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 24px #00000029;
  border: 0.30000001192092896px solid #70707073;
  opacity: 1;
  height: calc(100% - 7.3rem);
  width: 35%;
  right: 0;
  transform: translateX(0%);
  transition: transform 0.2s;
  z-index: 1000;
  bottom: 0;
}

.itp-menu-right-content.hidden {
  transform: translateX(100%);
  transition: transform 0.2s;
  margin-top: 70px;
  position: fixed;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0;
  border: 0.30000001192092896px solid #70707073;
  opacity: 1;
  height: calc(100% - 70px);
  width: 30%;
  right: 0;
  z-index: 3;
  overflow: auto;
  bottom: 0;
}

.back-arrow {
  cursor: pointer;
}

.back-arrow:hover {
  transform: scale(1.2);
}

.back-arrow:active {
  transform: scale(0.8);
}

.itp-menu-right-header {
  display: flex;
  gap: 10px;
  border: 1px solid rgba(67, 112, 151, 0.1);
  padding: 16px 0 4px 16px;
}

.itp-menu-right-back {
  text-align: left;
  font-family: "Montserrat";
  letter-spacing: 0px;
  color: #6D28D9;
  opacity: 1;
  cursor: pointer;
}

.itp-menu-right-title {
  text-align: left;
  font-family: "Montserrat";
  letter-spacing: 0px;
  color: #3a3a3a;
  opacity: 1;
  padding: 30px;
}

.itp-menu-right-body {
  height: calc(100% - 120px);
  padding: 30px;
  overflow-y: auto;
}

@media (max-width: 1265px) {
  .itp-menu-right-content.open {
    width: 50%;
  }
}

@media (max-width: 888px) {
  .itp-menu-right-content.open {
    width: 60%;
  }
}

@media (max-width: 740px) {
  .itp-menu-right-content.open {
    width: 70%;
  }
}

@media (max-width: 628px) {
  .itp-menu-right-content.open {
    width: 100%;
  }
}

.itp-menu-right-subtitle-container {
  margin-left: 0.75rem;
}

.itp-menu-right-subtitle {
  text-align: left;
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
  margin: 0 0 2rem 16px;
}
